import { EditProfileMenu, InboxFilters } from "../Constants/constants";
import { api, base } from "../config";

export const RoutePaths = {
    DASHBOARD: "dashboard",
    RECOMMENDED:'recommended-matches',
    PROFILE:"view-profile",
    INBOX:"inbox",
    CONVERSATION:"conversation",
    WEEKLYVIEWALL: "weekly-matches",
    ResponseReceived:"response-received",
    SIMILARMATCHES:"similar-matches",
    RECENTLYVIEW:"recently-viewed",
    // PROFILELOOKINGFOR:"profile-looking-for",
    PROFILELOOKINGFOR:"profiles-matching-the-partner-expectation",
    PROFILELOOKINGFORME:"profile-looking-forme",
    PHOTOREQUESTRECEIVED:'photo-request-received',
    MUTUALMATCHES:'mutual-matches',
    SELECTEDPROFILES:'selected-profile',
    EXPLOREMATCHES:'explore-matches',
    EDITPROFILE:'manage',
    EDITPROFILE_LITE: 'manage/new',
    REMOVE_PROFILE:'deleteprofile',
    SEARCH:"search",
    SAVEPARTNERPREFERENCES:'savepartnerpreferences',
    NOTIFICATION:'notification',
    MEMBERSHIP_REGISTER: 'membershipregister',
}

export const RouteHelper = {
    getRecommendedListUrl: `/${RoutePaths.DASHBOARD}/${RoutePaths.RECOMMENDED}`,
    getRecommendedProfileUrl: () => `/${RoutePaths.DASHBOARD}/${RoutePaths.RECOMMENDED}/${RoutePaths.PROFILE}`,
    getResponseReceivedProfileUrl: () => `/${RoutePaths.DASHBOARD}/${RoutePaths.ResponseReceived}/${RoutePaths.PROFILE}`,
    getPhotoRequestReceivedProfileUrl: () => `/${RoutePaths.DASHBOARD}/${RoutePaths.PHOTOREQUESTRECEIVED}/${RoutePaths.PROFILE}`,
    getInboxByTab: (tab) => `/${RoutePaths.INBOX}/${tab}`,
    getProfileById: (id) => `${RoutePaths.PROFILE}/${id}`,
    getConversationUrl: (tab) => `/${RoutePaths.INBOX}/${tab}/${RoutePaths.CONVERSATION}`,
    getEditProfileUrl: (tab) => {
        let temp = tab;
        const isV3User = localStorage.getItem("isV3User")

        if (temp == EditProfileMenu[1].urlPath && isV3User) {
            temp = EditProfileMenu[11].urlPath
        }

        return `/${isV3User ? RoutePaths.EDITPROFILE_LITE : RoutePaths.EDITPROFILE}/${temp ?? EditProfileMenu[0].urlPath}`
    },
    getProfileUrl: `/${RoutePaths.DASHBOARD}/${RoutePaths.PROFILE}`,
    getRecentlyViewedUrl:`/${RoutePaths.DASHBOARD}/${RoutePaths.RECENTLYVIEW}`,
    getSimilirMatchesUrl:`/${RoutePaths.DASHBOARD}/${RoutePaths.SIMILARMATCHES}`,
    getSavePartnerPreferenceUrl:`/${RoutePaths.SEARCH}/${RoutePaths.SAVEPARTNERPREFERENCES}`,
    getSelectedProfileUrl:`/${RoutePaths.DASHBOARD}/${RoutePaths.SELECTEDPROFILES}`,
    getExploreUrl:`/${RoutePaths.DASHBOARD}/${RoutePaths.EXPLOREMATCHES}`,
}

const RouteSegments = {
    PAYMENT_RESPONSE: "/api/getPaymentResponse",

    MATCH_LITE: "/new/matches",
    MATCH_CONVERSATIONS_LITE: "/new/match-conversations",
    CHAT_LITE: "/new/chat",
    FILTER_LITE: "/new/filter",
    MATCH_GROUP: "/new/match-family",
    OFFLINE_PROFLIE:"/new/offline-page",
    HOME: "/",
    FORGOT_PASSWORD : "/forgetPassword",
    OTP_VALIDATION_FOR_RESET: "/otpvalidationforreset",
    GET_OTP: "/getOTP",
    PROFILE_OTP:"/mobileVerification",
    BASIC_INFO: "/basicdata",
    DASHBOARD: "/dashboard",
    SEARCH: "/search",
    INBOX: "/inbox", 
    WEEKLYVIEWALL: "/weekly-matches",
    RECOMMENDED:'/recommended-matches',
    ResponseReceived:"/response-received",
    SIMILARMATCHES:"/similar-matches",
    RECENTLYVIEW:"/recently-viewed",
    PROFILELOOKINGFOR:"/profiles-matching-the-partner-expectation",
    PROFILELOOKINGFORME:"/profile-looking-forme",
    PHOTOREQUESTRECEIVED:'/photo-request-received',
    MUTUALMATCHES:'/mutual-matches',
    SELECTEDPROFILES:'/selected-profile',
    EXPLOREMATCHES:'/explore-matches',
    MATCHES: "/matches",
    //EDITPROFILE:'/manage',
    EDITPROFILE:'/manage/photos',
    EDITPROFILE_REJECT:'/manage/my-profile',
    NOTIFICATION:'/notification',
    NOTIFICATION_LITE: '/new/notification',
    TRIALPACK:'/new/matches/trialpackpopup',

    HELP:'/help',
    REMOVE_PROFILE:'/deleteprofile',
    SAVEPARTNERPREFERENCES:'/savepartnerpreferences',
    MEMBERSHIP_REGISTER: () => {
        const isV3User = localStorage.getItem("isV3User")
        return isV3User ? '/new/membershipregister' : '/membershipregister'
    },
    MEMBERSHIP_REGISTER_LITE: '/new/membershipregister',
    KALYAN_PREMIER:"/kalyan-premier",
    MOBILE_ABOUT_US :'/aboutus',
    MOBILE_TERMS_AND_CONDITION:'/android/api/termsconditions',

    JEWELLERY_MICROSITE:"/kalyanjewellers",

    //banner redirection
    BANNER_REDIRECTION_DEVELOPER:"/kalyandevelopers",
    BANNER_REDIRECTION_GOLD:"/gold-saving-scheme-online",
    BANNER_REDIRECTION_AKHAYATRITIYA:"/akhayatritiya",

    //google landing page
    ONLINE_MATRIMONY_WEBSITE:"/online-matrimony-website",

    ONLINE_MATRIMONY_WEBSITE_TAMIL:"/tamil",
    ONLINE_MATRIMONY_WEBSITE_LANDING_PAGE:"/landingpagev3",
    ONLINE_MATRIMONY_WEBSITE_KANNADA:"/kannada",
    ONLINE_MATRIMONY_WEBSITE_MALAYALAM:"/malayalam",
    ONLINE_MATRIMONY_WEBSITE_TELUGU:"/telugu",

    ONLINE_MATRIMONY_WEBSITE_FOR_TAMIL:"/matrimony-for-tamil",
    ONLINE_MATRIMONY_WEBSITE_FOR_TELUGU:"/matrimony-for-telugu",
    ONLINE_MATRIMONY_WEBSITE_FOR_KANNADA:"/matrimony-for-kannada",
    ONLINE_MATRIMONY_WEBSITE_FOR_MALAYALAM:"/matrimony-for-malayalam",
    ONLINE_MATRIMONY_WEBSITE_FOR_KERALA:"/matrimony-for-kerala",
    
    //footer
    ABOUT_KALYAN_JEWELLERS:"/gold-saving-scheme-online-about-us",
    ABOUT_US:"/about-us-kalyan-matrimony",
    CONTACT_US:"/contact-us-kalyan-matrimony",
    FEEDBACK:"/feedback-kalyan-matrimony",
    HELP_AND_FAQ: () => {
        const isV3User = localStorage.getItem("isV3User")
        return isV3User ? "/new/contact-us-for-help" : "/contact-us-for-help"
    },
    HELP_AND_FAQ_LITE: "/new/contact-us-for-help",
    HOW_IT_WORKS:"/how-it-works",
    PRIVACY_POLICY:"/privacy-policy",
    SAFETY_AND_SECURITY:"/safety-and-security",
    TERMS_AND_CONDITION:"/terms-and-conditions",
    DELETE_PHOTO:"/how-to-delete-photo",

    //more link registration
    HOME_PAGE:"/home",

    //gold saving scheme 
    GOLD_SAVING_SCHEME_ONLINE:"/gold-saving-scheme-online",
    VIEW_LEDGER:"/view-ledger",
    REGISTERED_SCHEME_LIST:"/registered-scheme-list",
    NEW_SCHEME_REGISTER:"/new-scheme-register",
    PAYMENT_HISTORY:"/payment-history",
    PAYMENT_VIEW:"/payment-view",
    CUSTOMER_INFORMATION:"/customer-information",
    CUSTOMER_UPDATE_ADDRESS:"/customer-update-address",
    CUSTOMER_UPDATE_IDPROFF:"/customer-update-idproff",
    
    //loginby
    LOGIN_FROM_ADMIN:"/loginfromadmin",
    LOGIN_FROM_EMAIL:"/loginfromemail",
    LOGIN_FROM_SMS:"/loginfromsms",
    LOGIN_FROM_PARTIAL_ADMIN:"/loginfromadminPartial",
    LOGIN_FROM_ADMIN_OFFLINE:"/loginfromadminoffline",
    LOGIN_FOR_AKSHAYA:"/akhayatritiya",
    LOGIN_FROM_OFFLINE:"/offline-profile",

    // NRI Page
    LOGIN_FROM_NRI:"/matrimony-for-nri",


    staticLinkForBookAppointment: "https://www.kalyanjewellers.net/top-wedding-jewellery-muhurat.php",

    staticLinkForAppDownload: {
        android: "https://play.google.com/store/apps/details?id=com.kalyanmatrimony.app",
        ios: "https://apps.apple.com/in/app/kalyan-matrimony-marriage-app/id6478538275",
    },

    staticLinkForGoldSavingSchemeOnline: api.API_URL+"/gold-saving-scheme-online",  // live link for gold scheme

    // staticLinkForGoldSavingSchemeOnline: "/",

    STORE_REGISTRATION: "/storeregistration",
 //aferRegister

 AFTER_REGISTRATION:"/afterRegistration",
 DOWNLOAD_PAGE:'/downloadPage',
 HOBBIES_PAGE:'/hobbiesPage',
 FAMILY_PAGE:'/familyPage',

    breadCrumbUrl: {
        "weekly-matches": "Weekly Matches",
        "matches": "Profile Page",
        "response-received": "Response Received",
        "recommended-matches": "Recommended",
        // "profile-looking-for": "Profile Looking For",
        "profiles-matching-the-partner-expectation": "Profile Looking For",
        "profile-looking-forme": "Profile Looking For Me",
        "photo-request-received": "Photo Request",
        "mutual-matches": "Mutual Matches",
        "selected-profile": "Selected Profile",
        "recently-viewed": "Recently Viewed",
        "similar-matches": "Similar Matches",
        "explore-matches":"Explore Matches"
    },
    inboxTab: (tab) => {
        let marged = []
        Object.keys(InboxFilters).forEach(key => {
            marged.concat(InboxFilters[key])
        })
        return `/inbox/${marged.find(filter => filter.id == tab)?.urlPath}`
    },
    editProfileTab: (tab) => {

        const isV3User = localStorage.getItem("isV3User")

        if (isV3User) {
            if (tab == 2) return `/manage/new/${EditProfileMenu[11]?.urlPath}`

            return `/manage/new/${EditProfileMenu.find(menu => menu.id == tab)?.urlPath}`
        }

        return `/manage/${EditProfileMenu.find(menu => menu.id == tab)?.urlPath}`
    },
    searchTab:(tab)=>`/search?tab=${tab}`,
}
export default RouteSegments;